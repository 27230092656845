"use client";

import Footer from "../../components/Footer";
import { useSession, SessionContext } from "../hooks/useSession";
import { Container } from "react-bootstrap";

export default function SessionLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const sessionContext = useSession({});

  return (
    <SessionContext.Provider value={sessionContext}>
      <Container fluid="sm">
        <div style={{ minHeight: "100vh" }} className="d-flex flex-column">
          {children}
          <Footer />
        </div>
      </Container>
    </SessionContext.Provider>
  );
}
