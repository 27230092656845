import { IconFuegoStats } from "./icons";

const Footer = () => (
  <div className="mb-3 mt-auto pt-2">
    <div
      className="d-flex justify-content-center align-items-center text-muted"
      style={{ fontSize: "80%" }}>
      <IconFuegoStats className="me-1" size={24} simple />
      Fuego Stats is Randy, Adam, Kelly &amp; Will
    </div>
  </div>
);

export default Footer;
